body {
  position: relative;
  margin: 0;
}
/* Overwriting Bootstrap to create an individual look'n'feel */
.label-warning {
  background-color: #c87704;
}
/*.btn-primary {
 background-color: $dark_ocher;
 border-color: $dark_ocher;
 color: #fff;
 }
 .btn-primary:focus,
 .btn-primary.focus,
 .btn-primary:hover {
 color: #fff;
 background-color: $ocher;
 border-color: $ocher;
 }
 */
select {
  margin-bottom: 10px;
}
.btn-toolbar .btn {
  margin-bottom: 5px;
}
/******** UI PARTITIONS *********/
/* aka Navigation */
.country-header {
  text-align: center;
  background: #2f5371;
  color: #fff;
  margin-top: 0px;
  padding-bottom: 15px;
}
.country-header h1 {
  font-size: 21px;
  font-weight: 600;
}
.country-header h1 span {
  font-size: 20px;
  font-weight: 200;
}
.page-content {
  padding-top: 15px;
  min-height: 400px;
  padding-bottom: 100px;
}
/* note: navbar header was removed from ui ... maybe delete the following */
.country-header h3.country-headline {
  color: #fff;
  text-transform: uppercase;
  font-size: 20pt;
  font-weight: 400;
  display: inline-block;
  padding: 10px;
  background-color: #5b4543;
  margin-top: 0px;
}
.authentication-area {
  padding-top: 20px;
  color: #fff;
  text-align: left;
}
.helper-widget {
  width: 200px;
  margin-top: 30px;
  background-color: #cb9550;
  color: #333;
}
.helper-widget p {
  font-size: 14pt;
}
.helper-widget p span {
  font-weight: bold;
}
/* Classes from the time pre-winterfell */
/*
 .hide-class{
 visibility: hidden;
 position: absolute;
 }
 .hide-class .show-class{
 visiblity: hidden !important;
 }
 */
/******* Dont show "visible" elements that are nested in "hidden" elements.
 "Visiblity"-property doesnt cascade down to the childs.
 "display"-property does that, but its not safe with forms ******/
/*
 :not(div.hide-class).show-class{
 visibility: visible;
 }*/
/* Input fields */
/* Edge-case: Nested checkboxes, appear just once in the form, hope it is safe */
.checkbox .checkbox {
  padding-left: 20px;
}
select.form-control {
  width: auto;
}
/* Winterfell */
.wf-panel {
  background-color: #e8f0f8;
  margin-bottom: 0;
  /* to make it stick to submit-warning box */
}
.wf-question-sets {
  padding: 10px;
}
/* style the paragraph as if it is a heading */
.wf-question > p {
  font-size: 20px;
  margin-top: 30px;
}
.wf-panel-heading {
  background-color: #5c7f9d;
  color: #fff;
}
.wf-panel-heading h3 {
  font-size: 20px;
}
.wf-panel-heading > p {
  font-size: 16pt;
  color: #ddd;
  margin-top: 10px;
  font-weight: 500;
}
.wf-question-set-header-container {
  /*
   font-size: 14pt;
   margin-top: 35px;
   border-top: 2px solid rgba(0, 0, 0, 0.14);
   */
}
.wf-question-set-header-container > h4 {
  font-size: 22px;
}
/* nested questions */
/*.wf-question>.wf-question{
 //border-left: 1px solid rgba(0, 0, 0, 0.17);
 padding-left: 15px;
 }*/
/*
 .level-1{
 padding-left: 0px !important;
 }
 .level-2{
 padding-left: 20px !important;
 }
 .level-3{
 padding-left: 30px !important;
 }
 .level-4{
 padding-left: 40px !important;
 }
 .level-5{
 padding-left: 50px !important;
 }*/
/********** Section 6 Collection Table style fixes ************/
.sec6-no-checkbox > .sec6-no-checkbox {
  border: 4px solid #c7d6e5;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
}
.sec6-no-checkbox > :last-child:not(.checkbox) {
  margin-bottom: 20px;
}
.sec6-table-action {
  font-size: 20px;
  color: #5c7f9d;
}
.sec6-table-column-title-12-1-1,
.sec6-table-column-title-12-1-3,
.sec6-table-other-title-12-1-3 > label,
.sec6-table-row-title-12-1-2 {
  width: 100%;
  padding: 5px 10px;
  background-color: #5c7f9d;
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
}
.sec6-table-other-title-12-1-3 > label {
  margin-bottom: 0px;
}
.sec6-table-row-title-12-1-1,
.sec6-table-other-title-12-1-1,
.sec6-table-row-title-12-1-3,
.sec6-table-other-title-12-1-3 {
  margin-top: 10px;
}
.sec6-table-row-title-12-1-1 label,
.sec6-table-other-title-12-1-1 label,
.sec6-table-row-title-12-1-3 label,
.sec6-table-other-title-12-1-3 label {
  width: 100%;
  border-bottom: 2px solid #d6dbe0;
  font-weight: 200;
  font-size: 16px;
  margin-bottom: 0;
}
.sec6-table-row-title-12-1-1 textarea,
.sec6-table-other-title-12-1-1 textarea,
.sec6-table-row-title-12-1-3 textarea,
.sec6-table-other-title-12-1-3 textarea {
  margin-top: 5px;
}
.sec6-table-row-title-12-1-1,
.sec6-table-row-title-12-1-3 {
  display: inline-block;
  width: 30%;
}
/* timeline questions */
.year-1,
.year-2 {
  width: 30%;
  text-align: center;
  padding-left: 0px !important;
  /* overwriting the level */
  display: inline-block;
  border: none !important;
}
.sec6-table-other-title-12-1-1 + .year-1,
.sec6-table-other-title-12-1-1 + .year-1 + .year-2 {
  width: 48%;
}
.year-1 {
  margin-right: 1%;
}
#question194 {
    width: 12%;
    display: inline-block;
}

#question194-label {
    display: inline-block;
    width: 18%;
}

#question3690 {
    width: 12%;
    display: inline-block;
}

#question3690-label {
    display: inline-block;
    width: 18%;
}



.sec6-table-other-title-12-1-1  {
    width: 100%;
}

.sec6-table-row-title-12-1-2 + div {
	margin-left: 30%;
}

#question254 {
	display: inline;
    width: 13%;
}

#question254-label {
    display: inline;
    margin-right: 10PX;
}

#question3750 {
    display: inline;
    width: 13%;
}

#question3750-label {
    display: inline;
    margin-right: 10 PX;
}

.sec6-table-other-title-12-1-1 + .year-1, .sec6-table-other-title-12-1-1 + .year-1 + .year-2 {
  width: 30%!important;
}
.sec6-table-other-title-12-1-1 + div {margin-left: 30%;}

.level-1 { margin-left: 5%; }
.level-2 { margin-left: 10%; }
.level-3 { margin-left: 15%; }
.level-4 { margin-left: 20%; }
.level-4 .year-2 { margin-left: 0%!important; }


/************** End of Section 6 Collection Table style fixes **************/
.wf-button-bar {
  position: relative;
  height: 45px;
  margin: 0 20px;
  padding-bottom: 75px;
  margin-bottom: 20px;
}
.middle-button {
  position: absolute;
  left: 50%;
  width: 100px;
  margin-left: -50px;
}
.submit-button {
  bottom: 0px;
}
.wf-panel-view-only {
  background-color: #fff;
}
.wf-panel-view-only .wf-panel-heading {
  background-color: #fff;
  color: #333;
  padding: 0;
  text-align: center;
}
.wf-panel-view-only h3 {
  font-weight: bold;
}
.wf-question-sets-view-only {
  pointer-events: none;
  background-color: #fff;
}
.wf-question-sets-view-only .form-control {
  width: 100%;
  background-color: #e0f0ff;
  border: none;
  box-shadow: none;
}
.wf-question-sets-view-only select {
  appearance: none;
}
.wf-question-sets-view-only .dropzone-dragging-area {
  display: none;
}

/* file path list is the only element on a read only form where clicking is allowed... */
.wf-question-sets-view-only .file-path-list a {
  pointer-events: auto;
}

.file-preview-muted .file-preview-name {
  opacity: 0.3;
}

.file-preview-action {
  cursor: pointer;
  color: #9e9e9e;
}

.file-preview-action:hover {
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: 0.5;
}

.file-path-list .file-preview .close {
  font-size: 14px;
  font-weight: 200;
}

.disabled-form {
  opacity: 0.3;
  pointer-events: none;
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #242729;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 5px solid #544563;
}
.footer ul.nav li {
  float: left;
  padding-right: 6.5px;
  margin-right: 5.85px;
  border-right: #737373 solid 1px;
  line-height: 1;
}
.footer ul.nav li a {
  padding: 0;
  margin: 0;
  color: #ddd;
  font-size: 9pt;
}

.file-upload-section .list-group-item {
  padding: 2px 10px;
}

.file-upload-section .alert {
  padding: 5px;
}

.dropzone-dragging-area {
  /* cursor: pointer;
  display: inline-block;
  background-color: #fff;
  width: 100%;
  height: 100px;
  border: 1px dashed #8f8f8f;
  border-radius: 5px;
  text-align: center; */
}
.dropzone-dragging-area span {
  //line-height: 100px;
}
.dropzone-preview {
  margin-top: 5px;
}
.dropzone-preview-image {
  width: auto;
  height: 50px;
  border: 2px solid #fff;
  margin-right: 5px;
}
.filename-preview {
}
.filename-preview > .filename {
  padding-left: 10px;
}

.filename-preview-muted {
  background-color: #c3c3c3;
}

.report-details {
  font-size: 16px;
}

.report-details .label {
  padding: 5px 15px;
  margin-right: 15px;
}

.download-section {
  text-align: right;
}
.alert {
  margin-bottom: 0;
  text-align: center;
}

.edit-report-feedback-area {
  margin-top: 30px;
}

.alert-pure {
  background: none;
  border: none;
}

.alert-small {
  text-align: left;
  padding: 5px;
}
