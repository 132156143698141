.react-spinner {
  position: relative;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin-top: 25px;
}

.react-spinner_bar {
  -webkit-animation: react-spinner_spin 1.2s linear infinite;
  -moz-animation: react-spinner_spin 1.2s linear infinite;
  animation: react-spinner_spin 1.2s linear infinite;
  border-radius: 5px;
  background-color: #000;
  position: absolute;
  width: 20%;
  height: 3.8%;
  top: -3.9%;
  left: -10%;
}

@keyframes react-spinner_spin {
 0% { opacity: 1; }
 100% { opacity: 0.15; }
}

@-moz-keyframes react-spinner_spin {
 0% { opacity: 1; }
 100% { opacity: 0.15; }
}

@-webkit-keyframes react-spinner_spin {
 0% { opacity: 1; }
 100% { opacity: 0.15; }
}