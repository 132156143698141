/*
 ****
 ****
 *** Those are the styles that are shared with the public POA website, including some general resets 
 *** Some of the styles are not needed in the reporting application, maybe clean up...
 ****
 ****
 */

*, *:before, *:after {
  box-sizing: border-box;
}
body {
  font-family: Helvetica, sans-serif;
  color: #333;
  background-color: #fff;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  position: relative;
}
header {
  position: relative;
  color: #fff;
  margin-bottom: 4px;
}
header h1 {
  display: inline-block;
  padding: 0 20px;
}
header a {
  display: inline-block;
  margin: 10px;
  padding: 10px;
  color: #fff;
  text-decoration: none;
  border-radius: 3px;
}
header a.active, header a:hover {
  color: #fff;
}
header .poa-top-header {
  background-color: #2c2c2c;
}
header .poa-top-header .inner {
  margin-left: 30px;
  position: relative;
}
header .poa-top-header a {
  font-size: 11pt;
  display: inline-block;
  margin: 3px;
  vertical-align: middle;
  padding: 3px;
}
header .poa-top-header a.un-brand {
  color: #3b92f8;
}
header .poa-top-header .un-logo {
  width: 34px;
  height: 29px;
  display: inline-block;
  background: url('../images/un-logo-white-49.png') no-repeat;
}
header .poa-brand-header {
  background: #2c2c2c url(../images/poa-thick-header-bg-only.png) no-repeat;
  position: relative;
  height: 195px;
  background-size: contain;
  background-position: top;
}
header .poa-brand-header .inner {
  position: relative;
  margin-left: 30px;
}
header .poa-brand-header .poa-logo {
  display: inline-block;
  margin-top: 25px;
  width: 165px;
  height: 71px;
  background: url(../images/POA-logo.png) no-repeat;
}
header .poa-brand-header .poa-subtitle {
  font-size: 16pt;
  font-weight: 100;
}
header .poa-brand-header .poa-subtitle span {
  display: block;
  /* HACK, Ich will eigentlich nur eine neue line */
}
/*End of HEADER */
.nav > li > a:focus, .nav > li > a:hover {
  text-decoration: none;
  background-color: #2c2c2c;
}
.nav-pills > li > a {
  background-color: #445157;
  color: white;
  text-transform: uppercase;
  font-size: 10pt;
  padding: 3px 5px;
  border-radius: 0px;
  /* overwriting the pills mixin */
}
/* react-router assigns active class to the a element instead of li (as bootstrap does) */
.nav-pills > li > a.active, .nav-pills > li > a:focus, .nav-pills > li > a:hover {
  background-color: #a95050;
}
.footer {
  background-color: #2c2c2c;
}
.footer ul > li {
  display: inline-block;
}
.footer a {
  color: #ddd;
}
.footer .footer-brand {
  margin-top: 10px;
}

